import qs from 'query-string';
import { getEnvStr } from '../utils';
import { getCookie } from '../utils/cookie';
import { getLocalAccessToken } from '../utils/getToken';
import { WX_USER_DATA } from '../utils/keys';
import { checkLoginStatus, loginByWexinOpenid } from './login';
import wxAutherize from './wxAutherize';

/**
 * 微信授权与登录
 * @param authorType 授权模式 "forcibly" 强制授权 "silence" 静默授权
 */
export const autherAndLogin = (authorType: 'forcibly' | 'silence'): Promise<string> =>
    new Promise(async (resolve, reject) => {
        // 获取授权后的微信用户信息
        let local_wxUserInfo = getCookie(getEnvStr(WX_USER_DATA));

        // 如果没有微信用户信息（即未授权）
        if (!local_wxUserInfo) {
            // 获取微信授权信息
            let openid = await wxAutherize(authorType);

            try {
                // openid 登录
                let res: any = await loginByWexinOpenid(openid, authorType == 'forcibly');
                resolve(res);
            } catch (error) {
                reject(error);
            }
        } else {
            // 已经尝试过登录-- 已授权但未登录（当前微信没有绑定高顿账号）
            reject('已授权，未绑定账号');
        }
    });

/**
 * 检查用户登录状态
 * @param authorType 授权模式 "forcibly" 强制授权 "silence" 静默授权 undefined 无需授权
 * @returns Promise<number> 1: 已登录， 2：未登录
 */
export default (authorType?: 'forcibly' | 'silence'): Promise<1 | 2> =>
    new Promise(async (resolve, reject) => {
        const accessToken = await getLocalAccessToken();
        // 检查token是否有效
        checkLoginStatus(accessToken)
            .then(() => {
                resolve(1);
            })
            .catch(e => {
                // 如果是公众号环境 && 需授权
                window['browser'].isgzh && !!authorType
                    ? // 唤起授权
                      autherAndLogin(authorType)
                          .then(() => {
                              resolve(1);
                          })
                          .catch(e => {
                              resolve(2);
                          })
                    : resolve(2);
            });
    });
