import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd-mobile';
import './index.css';

export type EmptyProps = {
    des?: string;
    btnText?: string;
    btnLink?: string;
    btnClick?: () => void;
};

class Empty extends React.Component<EmptyProps & RouteComponentProps, State> {
    static defaultProps = {
        des: '',
    };

    handleClick = e => {
        if (this.props.btnClick) {
            this.props.btnClick();
        } else if (this.props.btnLink) {
            this.props.history.push(this.props.btnLink);
        }
    };

    render() {
        const { des, btnText } = this.props;
        return (
            <div className="empty">
                <div className="empty-img"></div>
                {des && <div className="empty-des">{des}</div>}
                {btnText && (
                    <div className="empty-btns">
                        <Button className="my-button" type="primary" inline size="small" onClick={this.handleClick}>
                            {btnText}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Empty);
