import React from 'react';
import Loading from './loading';
import Empty, { EmptyProps } from './common/empty';

type PageLoadingProps = {
    loading: boolean;
    loadingText?: string;
    show?: boolean;
    empty?: boolean;
    emptyOpt?: EmptyProps;
};

class PageLoading extends React.Component<PageLoadingProps, State> {
    static defaultProps = {
        loading: true,
        empty: false,
    };
    render() {
        const { loading, show, empty, emptyOpt, loadingText } = this.props;
        return loading ? <Loading loadingText={loadingText} show={show} /> : empty ? <Empty {...emptyOpt} /> : this.props.children;
    }
}

export default PageLoading;
