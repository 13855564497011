import axios from 'axios';
import { getApigatewayHost, getEnvStr, json2formdata } from '../utils';
import { getCookie, setCookie } from '../utils/cookie';
import { VISITOR_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN, WX_USER_DATA, APPID } from '../utils/keys';
import { getSourceInfo } from './sourceinfo';
import { setUserLoginInfo } from './userInfo';
import { logout } from './login';

/**
 * 获取gdssid
 */
export const getGDSSID = (): Promise<string> =>
    new Promise((resolve, reject) => {
        let GDSSID = getCookie(getEnvStr('GDSSID'));
        if (!!GDSSID) {
            resolve(GDSSID);
            return;
        }
        const baseHost: string = getApigatewayHost();
        axios.get(`${baseHost}/sso/api/v1/makeidentity?source=scrm`).then(res => {
            let ggssid = res.data['GDSSID'];
            setCookie(getEnvStr('GDSSID'), ggssid);
            resolve(ggssid);
        });
    });

/**
 * 获取游客token
 */
export const getVisitorToken = (): Promise<string> =>
    new Promise(async (resolve, reject) => {
        const baseHost: string = getApigatewayHost();
        let GDSSID = await getGDSSID();
        axios
            .get(`${baseHost}/api/v1/token/get`, {
                params: {
                    AppID: APPID,
                    GDSSID,
                },
            })
            .then(res => {
                let { result, status } = res.data;
                if (!status) {
                    setCookie(getEnvStr(VISITOR_TOKEN), result);
                    resolve(result);
                } else {
                    reject('系统异常，请稍后再试');
                }
            })
            .catch(e => {
                reject(e);
            });
    });

/**
 * 获取微信用户信息
 * @param {string} openid
 */
export const getWexinUserInfoByOpenid = (openid: string): Promise<WxUserInfo> =>
    new Promise(async (resolve, reject) => {
        axios
            .get(`https://wechat.gaodunwangxiao.com/connect/oauth2/userinfo`, {
                params: {
                    openid: openid,
                },
            })
            .then((res: any) => {
                let { result, status, info } = res.data;
                if (!status) {
                    // setCookie(getEnvStr(WX_USER_DATA), result);
                    resolve(result);
                } else {
                    reject(info);
                }
            })
            .catch(e => {
                reject(e);
            });
    });

/**
 * 通过refreshtoken 获取accessToken
 */
export const refreshToken = (): Promise<string> =>
    new Promise((resolve, reject) => {
        const baseHost: string = getApigatewayHost();
        let refreshtoken = getCookie(REFRESH_TOKEN);
        let params = {
            appid: APPID,
            refreshtoken,
        };
        axios
            .post(`${baseHost}/api/v1/token/refreshtoken`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(res => {
                let { result, status } = res.data;
                if (!status) {
                    setCookie(ACCESS_TOKEN, result);
                    resolve(result);
                } else {
                    logout(true);

                    reject('登录过期');
                }
            })
            .catch(e => {
                reject(e);
            });
    });

/**
 * 通过wxcode 登录 ，登录完成后存储token在cookie，userinfo在localstrage
 * @param wxcode 微信授权code
 */
export const wxCodeLogin = (wxcode: any): Promise<string> =>
    new Promise(async (resolve, reject) => {
        const baseHost: string = getApigatewayHost();
        let GDSSID = await getGDSSID();
        let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
        let params = json2formdata({
            code: wxcode,
            appid: APPID,
            GDSSID: GDSSID,
            source: '1',
            PHPSESSIONID: PHPSESSID,
        });

        // wxcode登录
        axios
            .post(`${baseHost}/api/v1/code_login`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(async (res: any) => {
                let { result, info, accessToken, refreshToken } = res.data;
                // userDate 存在
                if (result && result.UserData) {
                    let { UserWxExtend } = result.UserData;
                    // 微信信息存在且不为0
                    if (UserWxExtend && UserWxExtend.length) {
                        let sourceInfo = await getSourceInfo();
                        // 轮询用户多公众号用户信息
                        for (let i = 0; i < UserWxExtend.length; i++) {
                            // 与当前公众号配置
                            if (UserWxExtend[i].WxAppId === sourceInfo.weChatAppId) {
                                setCookie(getEnvStr(WX_USER_DATA), UserWxExtend[i]);
                                break;
                            }
                        }
                    }
                }

                // 如果两个token都存在，表示登录成功
                if (accessToken && refreshToken) {
                    setUserLoginInfo(res.data);

                    resolve(accessToken);
                } else {
                    // 登录失败, 当前微信没有绑定高顿账号
                    reject(info);
                }
            });
    });
