import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getApigatewayHost } from './index';
import { logout } from '../service/login';
import getToken from './getToken';
import qs from 'query-string';
import { getCustomArgs } from '../service/CustomArgsService';

const LoginErrorStatus = [553649445, 553649446, 553649447, 553649448, 553649449, 553649456, 553649409, 553649437, 553649434];

const instance = axios.create({
    baseURL: getApigatewayHost(),
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 处理请求request
instance.interceptors.request.use(
    async (config: AxiosRequestConfig & { visitor?: boolean, extendArgs?: any}) => {
        const { visitor, extendArgs } = config;
        const token = await getToken(visitor);
        config.headers.common['Authentication'] = `Basic ${token}`;
        const { url, query } = qs.parseUrl(window.location.href);
        const zdy = getCustomArgs();
        config.headers.common['X-Requested-Extend'] = JSON.stringify({
            referer: qs.stringifyUrl({
                url,
                query: {
                    ...zdy,
                    ...query,
                },
            }),
            ...extendArgs,
        });
        return Promise.resolve(config);
    },
    error => Promise.reject(error)
);

// 预处理返回值
instance.interceptors.response.use(
    (response: AxiosResponse) => {
        // 登录失效
        if (LoginErrorStatus.includes(response.data.status)) {
            // 删除token
            logout(true);
            // 刷新
            window.location.reload();
        }
        return Promise.resolve(response.data);
    },
    error => {
        // Toast.show('服务器或网络异常', 3, false)
        return Promise.reject(error);
    }
);

export default class Fetch {
    options: any;
    constructor(options = {}) {
        this.options = options;
    }

    request(options): Promise<any> {
        return instance.request(options);
    }

    get(url: string, data, options = {}) {
        return this.request({
            url,
            params: { ...data, stamp: Date.now() },
            ...options,
        });
    }

    post(url, data, options = {}) {
        return this.request({
            method: 'post',
            url,
            data,
            ...options,
        });
    }

    put(url, data, options = {}) {
        return this.request({
            method: 'put',
            url,
            data,
            ...options,
        });
    }

    del(url, options = {}) {
        return this.request({
            method: 'delete',
            url,
            params: {
                ...options,
            },
        });
    }
}
const fetch = new Fetch();
export const request = fetch.request.bind(fetch);
export const get = fetch.get.bind(fetch);
export const post = fetch.post.bind(fetch);
export const put = fetch.put.bind(fetch);
export const del = fetch.del.bind(fetch);

/**
 * 接口调用说明
 * @param url 请求链接
 * @param data 请求参数
 * @param options 请求配置
 ``` 
    {
        visitor: boolean, // 标记当前请求是否使用visitor token; true：使用visitortoken， false：必须使用正式token
        headers: {
            ...
        }
    }
 ```
 */
