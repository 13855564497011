import { Base64 } from 'js-base64'; //需要npm安装js-base64
import { refreshToken } from './tokenless';

/**
 * 检查token有效期
 * @param token gaodun accesstoken
 * @returns true:未过期 false:已过期
 */
export const checkTokenExpired = (token: string) => {
    let str_token = token.split('.')[1];
    let obj_token = JSON.parse(Base64.decode(str_token));
    let exp = obj_token.exp; //过期时间
    let nowTime = Date.now() / 1000; //当前时间
    return nowTime < exp;
};

/**
 * 检查token是否过期，如果过期使用refreshtoken获取
 * @param localToken 本地accesstoken
 *
 */
export const checkToken = (localToken: string): Promise<string> =>
    new Promise((resolve, reject) => {
        // 在有效期
        checkTokenExpired(localToken)
            ? resolve(localToken)
            : refreshToken()
                  .then(token => {
                      // 重新获取token
                      resolve(token);
                  })
                  .catch(info => {
                      reject(info);
                  });
    });

export default checkToken;
