/**
 * 因app中iframe无法使用rainbow中方法
 * ready及run执行判断全部添加iframe过滤
 */
import { isApp } from '@gaodun.com/rainbow';

/**
 * 判断是否为iframe环境
 * @returns boolean
 */
export const isIframe = () => {
    return window.self !== window.top;
};

/**
 * app加载判断
 * @param fn 执行函数
 * @returns 函数执行
 */
export const jsbridgeReady = fn => {
    // 非app环境 || iframe环境
    if (!isApp() || isIframe()) {
        return fn();
    }
    try {
        // 若GaodunJSBridge已注入完成
        // @ts-ignore
        if (window.GaodunJSBridge !== undefined) {
            return fn();
        }
        // 等待GaodunJSBridge注入完成
        document.addEventListener(
            'GaodunJSBridgeReady',
            event => {
                fn();
            },
            false
        );
    } catch (e) {}
};

interface jsbridgeRunOptions {
    new?: Function;
    web?: Function;
}
/**
 * 区分环境执行不同函数
 * @param options 执行环境配置
 * @returns
 */
export const jsbridgeRun = (options: jsbridgeRunOptions) => {
    let opts = {
        new: function () {},
        web: function () {},
        ...options,
    };
    // 非app环境 || iframe环境
    if (!isApp() || isIframe()) {
        return opts.web();
    }
    return opts.new();
};
