/**
 * 初次参数GD_ZDY2; 最后一次参数GD_ZDY;
 * 多次访问页面，但携带的自定义参数不同；取初次参数以及最后一次参数
 * 该参数有效其为 24H 超出全部失效，重新记录（保存时间戳，计算是否失效）
 * 初次保存2个参数，再次覆盖最后一次参数（GD_ZDY）参数
 */
import { GD_ZDY } from '../utils/keys';

// 保存自定义参数
export const setCustomArgs = (val: string) => {
    const local = localStorage.getItem(GD_ZDY);
    const localParse = local ? JSON.parse(local) : {};
    const nowDate = Date.now();
    const isExpires = localParse.start + 24 * 3600 * 1000 < nowDate;

    // 本地无数据 || 已过期
    if (!local || isExpires) {
        return localStorage.setItem(
            GD_ZDY,
            JSON.stringify({
                start: nowDate,
                zdy: [val],
            })
        );
    }

    // 若本次插入与上次不同
    if (val != localParse.zdy[0]) {
        localParse.zdy.unshift(val);
        return localStorage.setItem(GD_ZDY, JSON.stringify(localParse));
    }

    // 若相同无需处理
};

// 获取自定义参数
export const getCustomArgs = () => {
    return {};
    // const local = localStorage.getItem(GD_ZDY);
    // const {start, zdy}: any = local ? JSON.parse(local) : {};
    // const nowDate = Date.now();
    // const isExpires = start + 24 * 3600 * 1000 < nowDate;

    // // 本地无数据 || 已过期
    // if(!local || isExpires) {
    //     return {}
    // }
    // console.log({
    //     GD_ZDY: zdy[0],
    //     GD_ZDY2: zdy[1]
    // })

    // return {
    //     GD_ZDY: zdy[0],
    //     GD_ZDY2: zdy[1]
    // };
};
