import React, { useState } from 'react';
import { getEnv } from '../../utils';
import '../../assets/css/protocol-check.less';
import { getAgreementList } from '../../service/api';

type ProtocolCheckProps = {
    checked?: boolean;
    showUserInfoProtocol?: boolean;
    onChange?: (check: boolean) => void;
    onRef?: (e: ProtocolCheck) => void;
    showProtocol?: boolean;
    agreements?: number | null;
} & Props;


export default class ProtocolCheck extends React.Component<ProtocolCheckProps, State> {
    static defaultProps = {
        showProtocol: false,
        showUserInfoProtocol: true,
    };
    constructor(props) {
        super(props);

        this.state = {
            shake: false,
            CourseBuyProtocolList: []
        };

        this.props.onRef && this.props.onRef(this);
    }
    handleCheckChange = newCheck => {
        this.props.onChange && this.props.onChange(newCheck);
    };
    shake = () => {
        this.setState(
            {
                shake: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        shake: false,
                    });
                }, 1000);
            }
        );
    };

    getProtocolList = async() => {
        const res = await getAgreementList()
        if(res.status === 0) {
            this.setState({
                CourseBuyProtocolList: res?.result
            })
        }
    }

    componentDidMount(): void {
        this.getProtocolList()
    }

    render() {
        let { shake, CourseBuyProtocolList } = this.state;
        let { checked, showUserInfoProtocol, showProtocol, agreements } = this.props;
        let courseBuyProtocolInfo = CourseBuyProtocolList.find(item => item.specificProductAgreements === (agreements || 0))
        return (
            <div className={`protocol-check  ${!!shake ? 'shake' : ''}`}>
                <i className={`checkbox ${checked ? 'checked' : ''}`} onClick={() => this.handleCheckChange(!checked)}></i>

                <span>我已阅读并同意</span>
                {
                    showProtocol && (
                        <>
                        <a className="protocol-link" href={courseBuyProtocolInfo?.agreementsUrl || ''}>
                            {courseBuyProtocolInfo?.agreementsName || ''}
                        </a>
                        <span> 、</span>
                    </>
                    )
                }
                {!!showUserInfoProtocol && (
                    <>
                        <a className="protocol-link" href="https://s.gaodunwangxiao.com/static-resource/privicay/usageProtocol.html">
                            《用户协议》
                        </a>
                        <span>和</span>
                        <a className="protocol-link" href="https://s.gaodunwangxiao.com/static-resource/privicay/privicay.html">
                            《隐私政策》
                        </a>
                    </>
                )}
            </div>
        );
    }
}
