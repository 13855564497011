import React from 'react';
import { Picker, Button, Icon, InputItem, Toast } from 'antd-mobile';
import ProtocolCheck from '../protocol/check';
import { getCountryCodeList, sendVerifyCode, diffEnvLoginByVerify } from '../../service/login';
import './login.css';

type BindPhoneProps = {
    onSuccess?: Function;
    onError?: Function;
};
class Login extends React.Component<Props & BindPhoneProps, State> {
    constructor(props) {
        super(props);

        this.state = {
            areaCode: ['86'],
            phone: '',
            phoneError: false,
            verify: '',
            verifyError: false,
            checked: false,
            countdown: 0,
            hassend: false,
            submitLoading: false,
            countryCodeList: [],
        };
    }
    senderTimer?: number;
    checkEle?: ProtocolCheck;

    componentWillMount() {
        getCountryCodeList().then(list => {
            this.setState({
                countryCodeList: list,
            });
        });
    }
    componentWillUnmount() {
        window.clearInterval(this.senderTimer);
    }

    verifyPhoneNum = (value: string, areaCode) => {
        return areaCode[0] === '86' ? !/1[\d]{10}$/.test(value.replace(/\s/g, '')) : !/[\d]{5,}$/.test(value.replace(/\s/g, ''));
    };

    handleAreaCodeChange = value => {
        const { phone } = this.state;
        this.setState({
            areaCode: value,
            phoneError: this.verifyPhoneNum(phone, value),
        });
    };

    handlePhoneChange = value => {
        const { areaCode } = this.state;
        // 如果是大陆号码
        this.setState({
            phoneError: this.verifyPhoneNum(value, areaCode),
            phone: value,
        });
    };

    handleVerifyChange = value => {
        this.setState({
            verifyError: !/[\d]{6}$/.test(value.replace(/\s/g, '')),
            verify: value,
        });
    };

    handleSendVerifyCode = () => {
        let { countdown, phone, phoneError, areaCode } = this.state;
        // countdown 为零可以发送
        if (!countdown) {
            // 如果手机号有误
            if (!phone || phoneError) {
                Toast.show('请输入正确手机号', 3, false);
                return;
            }
            sendVerifyCode(phone, areaCode[0])
                .then(msg => {
                    Toast.show(msg, 1.5, false);
                    this.startCountDown();
                })
                .catch(e => {
                    Toast.show(e, 3, false);
                });
        }
    };

    startCountDown = () => {
        this.setState({
            countdown: 60,
            hassend: true,
        });
        this.senderTimer = window.setInterval(() => {
            let { countdown } = this.state;
            if (countdown > 0) {
                this.setState({
                    countdown: countdown - 1,
                });
            } else {
                this.setState({
                    countdown: 0,
                });
                window.clearInterval(this.senderTimer);
            }
        }, 1000);
    };

    handleLoginAndBind = () => {
        const { areaCode, phone, verify, phoneError, verifyError, checked } = this.state;
        if (!phone || phoneError) {
            return Toast.show(`请输入${!!phone ? '正确的' : ''}手机号`, 3, false);
        }
        if (!verify || verifyError) {
            return Toast.show(`请输入${!!phone ? '正确的' : ''}验证码`, 3, false);
        }
        if (!checked) {
            return this.checkEle && this.checkEle.shake();
        }
        this.setState({
            submitLoading: true,
        });
        let area = areaCode[0] === '86' ? '' : areaCode[0];
        // 通过验证码登录
        diffEnvLoginByVerify(phone, area, verify)
            .then(data => {
                Toast.success('登录成功', 3, undefined, false);
                this.setState({
                    submitLoading: false,
                });
                this.props.onSuccess && this.props.onSuccess();
            })
            .catch(e => {
                Toast.show(e, 3, false);
                this.setState({
                    submitLoading: false,
                });
                this.props.onError && this.props.onError();
            });
    };

    render() {
        const { countryCodeList, areaCode, phone, phoneError, verify, verifyError, countdown, hassend, submitLoading, checked } =
            this.state;
        return (
            <div className="ace-login-box">
                <div className="ace-login-hd">{!this.props.loginStatus.accessToken ? '登录' : '绑定手机号'}</div>
                <div className="ace-login-form">
                    <div className="ace-login-item">
                        <InputItem
                            type="number"
                            value={phone}
                            placeholder="请输入手机号"
                            clear
                            maxLength={areaCode[0] === '86' ? 11 : 20}
                            onChange={this.handlePhoneChange}
                            error={phoneError}
                            onErrorClick={() => Toast.info(`请输入${areaCode[0] === '86' ? '11位' : '5位以上'}数字`)}
                        >
                            <Picker
                                title="选择区号"
                                extra="请选择(可选)"
                                data={countryCodeList}
                                value={areaCode}
                                cols={1}
                                onChange={this.handleAreaCodeChange}
                                onOk={this.handleAreaCodeChange}
                            >
                                <div className="ace-login-areacode">
                                    +{areaCode[0]}
                                    <Icon type="down" className="" />
                                </div>
                            </Picker>
                        </InputItem>
                    </div>
                    <div className="ace-login-item">
                        <InputItem
                            type="number"
                            value={verify}
                            placeholder="请输入验证码"
                            clear
                            maxLength={6}
                            onChange={this.handleVerifyChange}
                            error={verifyError}
                            onErrorClick={() => Toast.info('请输入6位数字')}
                            labelNumber={0}
                            extra={
                                <div
                                    className={!countdown ? 'ace-login-verifycode' : 'ace-login-verifycode disabled'}
                                    onClick={this.handleSendVerifyCode}
                                >
                                    {!countdown ? (hassend ? '重新获取' : '获取验证码') : `${countdown}秒后重发`}
                                </div>
                            }
                        />
                    </div>

                    <div className="ace-login-protocol">
                        <ProtocolCheck
                            onRef={e => {
                                this.checkEle = e;
                            }}
                            checked={checked}
                            onChange={newCheck => this.setState({ checked: newCheck })}
                        />
                    </div>
                    <Button
                        className="my-button"
                        type="primary"
                        loading={submitLoading}
                        disabled={submitLoading}
                        onClick={this.handleLoginAndBind}
                    >
                        登录
                    </Button>
                </div>
            </div>
        );
    }
}

export default Login;
