import { UPDATA_LOGIN_STATUS, UPDATA_BINDPHONE_VISIT } from '../types/account';

const initialLoginStatusState = {};
export const loginStatus = (state = initialLoginStatusState, action) => {
    switch (action.type) {
        case UPDATA_LOGIN_STATUS:
            return Object.assign({}, state, action.payload);

        default:
            return state;
    }
};
export const bindPhoneVisit = (state = false, action) => {
    switch (action.type) {
        case UPDATA_BINDPHONE_VISIT:
            state = action.data;
            return state;

        default:
            return state;
    }
};
