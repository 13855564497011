import React from 'react';
import Empty from '../components/common/empty';

class Page404 extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <Empty des="您所访问的页面不存在" btnText="前往首页" btnLink="/" />;
    }
}
export default Page404;
