import Loadable from 'react-loadable';
import Page404 from '../pages/page404';
import Loading from '../components/loading';

const loadable = component =>
    Loadable({
        loader: component,
        loading: Loading,
    });

const routes: routeItem[] = [
    {
        path: '/',
        name: '首页',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/index')),
    },
    {
        path: '/activitydetail',
        name: '课程详情',
        exact: true,
        visitor: true,
        component: loadable(() => import('../pages/activity-detail')),
    },
    {
        path: '/activitydetail/:activityId',
        name: '课程详情',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/activity-detail')),
    },
    {
        path: '/activitydetail/:activityId/:productId',
        name: '课程详情',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/activity-detail')),
    },
    {
        path: '/experienceLink',
        name: '中转页',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/experienceLink')),
    },
    {
        path: '/groupbuy',
        name: '团购详情',
        exact: true,
        visitor: true,
        component: loadable(() => import('../pages/group-buy')),
    },
    {
        path: '/groupbuy/:activityId',
        name: '团购详情',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/group-buy')),
    },
    {
        path: '/groupbuy/:activityId/:productId',
        name: '团购详情',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/group-buy')),
    },
    {
        path: '/orderpay',
        name: '订单确认',
        exact: true,
        visitor: true,
        authorized: 'silence',
        component: loadable(() => import('../pages/order-pay')),
    },
    {
        path: '/payment',
        name: '高顿网校安全支付',
        exact: true,
        visitor: false,
        authorized: 'silence',
        component: loadable(() => import('../pages/payment')),
    },
    {
        path: '/orderpayresult',
        name: '高顿网校安全支付',
        exact: true,
        visitor: false,
        authorized: 'silence',
        component: loadable(() => import('../pages/order-pay-result')),
    },
    {
        path: '/orderpay/:productId',
        name: '订单确认',
        exact: true,
        visitor: true,
        authorized: 'silence',
        component: loadable(() => import('../pages/order-pay')),
    },
    {
        path: '/orderpay/:activityId/:productId',
        name: '订单确认',
        exact: true,
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/order-pay')),
    },
    {
        path: '/success/:orderToken',
        name: '购买成功',
        authorized: 'silence',
        component: loadable(() => import('../pages/success')),
    },
    {
        path: '/teacherassign/:orderToken',
        name: '匹配助教',
        authorized: 'forcibly',
        component: loadable(() => import('../pages/teacher-assign')),
    },
    {
        path: '/community',
        name: '添加社群',
        authorized: 'forcibly',
        component: loadable(() => import('../pages/community')),
    },
    {
        path: '/studytips/:orderToken',
        name: '前往我的班级',
        authorized: 'forcibly',
        component: loadable(() => import('../pages/study-tips')),
    },
    {
        path: '/classlist',
        name: '学习',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/class-list')),
    },
    {
        path: '/classdetail',
        name: '学习详情',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/class-detail')),
    },
    {
        path: '/mypage',
        name: '我的',
        authorized: 'forcibly',
        component: loadable(() => import('../pages/my-list')),
    },
    {
        path: '/myteacher',
        name: '我的助教',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/my-teachers')),
    },
    {
        path: '/orderlist',
        name: '我的订单',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/order-list')),
    },
    {
        path: '/orderdetail/:id',
        name: '订单详情',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/order-detail')),
    },
    {
        path: '/activitylist',
        name: '我的活动',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/activity-list')),
    },
    {
        path: '/appointsign',
        name: '活动详情',
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/appointsign')),
    },
    {
        path: '/getmaterial',
        name: '活动详情',
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/getmaterial')),
    },
    {
        path: '/appointsigndetail/:id',
        name: '活动详情',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/appointsign-detail')),
    },
    {
        path: '/couponlist',
        name: '我的优惠券',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/coupon-list')),
    },
    {
        path: '/bindphone',
        name: '手机号绑定',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/bind-phone')),
    },
    {
        path: '/userinfoedit',
        name: '个人信息编辑',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/userinfo-edit')),
    },
    {
        path: '/questionnaire',
        name: '活动详情',
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/questionnaire')),
    },
    {
        path: '/groupsurvey',
        name: '活动详情',
        visitor: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/group-survey')),
    },
    {
        path: '/lotterydraw',
        name: '活动详情',
        authorized: 'forcibly',
        component: loadable(() => import('../pages/lottery-draw')),
    },
    {
        path: '/lotterypage',
        name: '活动详情',
        authorized: 'forcibly',
        component: loadable(() => import('../pages/lottery-page')),
    },
    {
        path: '/qasuccess',
        name: '成功',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/qasuccess')),
    },
    {
        path: '/msgsubscript',
        name: '活动消息通知绑定',
        visitor: true,
        authorized: 'silence',
        component: loadable(() => import('../pages/msg-subscript')),
    },
    {
        path: '/msgsubscriptresult',
        name: '活动消息通知绑定结果',
        userStrict: true,
        authorized: 'silence',
        component: loadable(() => import('../pages/msg-subscript-result')),
    },
    {
        path: '/redirect',
        name: 'loading',
        userStrict: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/redirect')),
    },
    {
        path: '/living',
        name: 'loading',
        visitor: true,
        authorized: 'silence',
        component: loadable(() => import('../pages/living')),
    },
    {
        path: '/systeminfo',
        name: 'token详情',
        visitor: true,
        component: loadable(() => import('../pages/system-info')),
    },
    {
        path: '/readarticles',
        name: '阅读文章',
        visitor: true,
        component: loadable(() => import('../pages/readarticles')),
    },
    {
        path: '/g2list/:courseId',
        name: '商品列表',
        visitor: true,
        component: loadable(() => import('../pages/windmill-product')),
    },
    {
        path: '/g2player/:videoId/:resourceId',
        name: '视频',
        visitor: true,
        component: loadable(() => import('../pages/glive2-player')),
    },
    {
        path: '/transfer',
        name: '跳转中',
        visitor: true,
        component: loadable(() => import('../pages/transfer')),
    },
    {
        path: '/grouplist',
        name: '组合列表',
        exact: true,
        visitor: true,
        component: loadable(() => import('../pages/group-list')),
    },
    {
        path: '/teacher/:projectId',
        name: '助教',
        exact: true,
        authorized: 'forcibly',
        component: loadable(() => import('../pages/teacher')),
    },
    {
        path: '/baoyanReport',
        name: '保研专属报告',
        exact: true,
        visitor: true,
        component: loadable(() => import('../pages/baoyan-report')),
    },
    {
        path: '/bookActivation',
        name: '图书激活',
        exact: true,
        visitor: true,
        component: loadable(() => import('../pages/book-activation')),
    },
    {
        path: '/bookActivationQa',
        name: '图书激活-常见问题',
        exact: true,
        visitor: true,
        component: loadable(() => import('../pages/book-activation-qa')),
    },
    {
        path: '*',
        name: '404',
        visitor: true,
        component: Page404,
    },
];

export default routes;
