import React from 'react';
import { Modal } from 'antd-mobile';
import './index.css';

type DrawerDialogProps = {
    className?: string;
    showClose?: '' | 'left' | 'right';
    maskClose?: boolean;
    visible: boolean;
    title?: string;
    onOpenChange: Function;
    top?: string;
};

class DrawerDialog extends React.Component<Props & DrawerDialogProps, State> {
    static defaultProps = {
        showClose: '',
        maskClose: true,
    };

    render() {
        const { className, visible, showClose, maskClose, title, heightAuto, circle } = this.props;
        return (
            <Modal
                popup
                maskClosable={maskClose}
                className={`drawer-dialog ${heightAuto ? 'height_auto' : ''} ${circle ? 'circle' : ''} ${className}`}
                visible={visible}
                onClose={() => this.props.onOpenChange(false)}
                animationType="slide-up"
            >
                <div className="drawer-dialog-box">
                    <div className="drawer-dialog-hd">
                        {/* <div className="drawer-close-bar" onClick={() => this.props.onOpenChange(false)}></div> */}
                        {!!showClose && (
                            <div className={`drawer-close-btn ${showClose}`} onClick={() => this.props.onOpenChange(false)}>
                                <i className="iconfont iconclose"></i>
                            </div>
                        )}
                        {!!title && <div className="drawer-dialog-tt">{title}</div>}
                    </div>
                    <div className="drawer-dialog-bd">{this.props.children}</div>
                </div>
            </Modal>
        );
    }
}

export default DrawerDialog;
