import CallApp from 'callapp-lib';
import { getWxSignature } from './api';
import qs from 'query-string';
import { sleep } from '../utils';

/**
 * 微信公众号初始化
 * @param source 公众号对应source
 */
export const wxConfigInit = async sourceInfo => {
    if (window['browser'].iswx) {
        // 若还未加载成功
        if(!window['wx']) {
            await sleep(300);
            return wxConfigInit(sourceInfo)
        }
        // 获取签名
        let res: any = await getWxSignature({
            appid: sourceInfo.weChatAppId,
            uri: window.location.href,
        });
        const { appId, noncestr, signature, timestamp } = res.result;
        // wxjssdk初始化
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: noncestr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp'],
        });
    }
};

/**
 * 微信分享配置
 * @param title 分享标题
 * @param link 分享链接
 * @param imgUrl 分享图标
 * @param disc 分享描述
 * @param cb 配置成功回调
 */
export const wxShareCfg = async (title = '', link = '', imgUrl = '', disc = '', cb?: Function) => {
    if(window['browser'].iswx) {
        // 若还未加载成功 300ms后重新执行
        if(!window['wx']) {
            await sleep(300);
            return wxShareCfg(title, link, imgUrl, disc, cb)
        }
        window['wx']?.ready(function () {
            //需在用户可能点击分享按钮前就先调用
            wx?.updateAppMessageShareData({
                title: title, // 分享标题
                desc: disc, // 分享描述
                link: deleteUrlArgsWxCode(link), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    console.log('updateAppMessageShareData OK');
                    cb && cb();
                },
            });
            wx?.updateTimelineShareData({
                title: title, // 分享标题
                link: deleteUrlArgsWxCode(link), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    // console.log('updateTimelineShareData OK')
                    cb && cb();
                },
            });
        });
    }
};

/**
 * 如分享链接包含openid, status删除
 * @param link 传入的分享链接
 * */
export const deleteUrlArgsWxCode = (link: string): string => {
    if (!~link.indexOf('openid')) return link;

    const linkObj = qs.parseUrl(link);
    let { openid, status, ...querys } = linkObj.query;
    let newlink = qs.stringifyUrl({
        url: linkObj.url,
        query: querys,
    });

    return newlink;
};

/**
 * 唤起微信app
 */
export const openWXapp = () => {
    // 如果是微信浏览器
    if (window['browser'].iswx) {
        return;
    }
    const options = {
        scheme: { protocol: 'weixin' },
        intent: {
            package: 'com.tencent.mm',
            scheme: 'weixin',
        },
        //apple store
        appstore: 'https://itunes.apple.com/cn/app//id414478124?mt=8',
        //应用宝
        yinyongbao: 'https://android.myapp.com/myapp/detail.htm?apkName=com.tencent.mm',
        fallback: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.tencent.mm', //唤端失败后跳转的地址
    };
    const callLib = new CallApp(options);
    callLib.open({
        path: '',
    });
};
