export const ACCESS_TOKEN = 'Accesstoken';
export const REFRESH_TOKEN = 'Refreshtoken';
export const VISITOR_TOKEN = 'AccessVisittoken';
export const GLIVE_DEBUG_TOKEN = 'GliveDebugToken';
export const TOKEN_SOURCE = 'TOKEN_SOURCE';
export const WX_CODE = 'WXAutherizeCode';
export const WX_OPENID = 'WX_OPENID';
export const WX_USER_DATA = 'WX_USER_DATA';
export const USER_INFO = 'Userinfo';
export const APPID = '200504';
export const PROJECT_INFO = 'ProjectInfo';
export const SOURCE_INFO = 'SourceInfo';
export const GZH_SOURCE = 'gzh_source';
export const ADDRESS_ADD = 'address_add';
export const ACTIVITY_STOCK_COUNT = 'activity_stock_count';
export const ACTIVITY_COUNT_EXPIRES = 'activity_count_expires';
export const AUTHOR_TIMESTAMP = 'author_timestamp';
export const WX_TYPE = 'wx_type';

export const GD_ZDY = 'GD_ZDY';
export const ROOM_ID = 'roomId';
