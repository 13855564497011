export default class Captcha {
    onCaptchaSuccess: any;
    captcha: null;
    appid: string;
    constructor(props) {
        // console.log('Captcha props', props);
        this.onCaptchaSuccess = props.onCaptchaSuccess;
        // this.onCaptchaSuccess = this.onCaptchaSuccess.bind(this);
        this.captcha = null;
        this.appid = '194929617';
    }

    getCaptcha() {
        return this.captcha;
    }

    loadJS() {
        return new Promise((resolve, reject) => {
            const _domScript = document.getElementById('login_captcha_script_tag');
            if (_domScript) {
                resolve('loaded');
            } else {
                let domScript = document.createElement('script');
                domScript.type = 'text/javascript';
                domScript.id = 'login_captcha_script_tag';
                domScript.src = 'https://turing.captcha.qcloud.com/TCaptcha.js';

                domScript.onload = function () {
                    resolve('loaded');
                };
                domScript.onerror = function () {
                    reject('loaded');
                };
                document.getElementsByTagName('head')[0].appendChild(domScript);
            }
        });
    }

    callback(res) {
        this.captcha = res;
        this.onCaptchaSuccess && this.onCaptchaSuccess(res);
    }

    loadErrorCallback() {
        const appid = this.appid;
        // 生成容灾票据或自行做其它处理
        const ticket = 'terror_1001_' + appid + '_' + Math.floor(new Date().getTime() / 1000);
        this.callback({
            ret: 0,
            randstr: '@' + Math.random().toString(36).substr(2),
            ticket: ticket,
            errorCode: 1001,
            errorMessage: 'jsload_error',
        });
    }

    showCaptcha() {
        try {
            // 生成一个验证码对象
            // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
            //callback：定义的回调函数
            this.loadJS()
                .then(() => {
                    // @ts-ignore
                    const captcha = new TencentCaptcha(this.appid, this.callback.bind(this), { userLanguage: 'zh-cn' });
                    // 调用方法，显示验证码
                    captcha.show();
                })
                .catch(() => {
                    this.loadErrorCallback();
                });
        } catch (error) {
            // 加载异常，调用验证码js加载错误处理函数
            console.log('error', error);
            this.loadErrorCallback();
        }
    }
}
