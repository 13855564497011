import qs from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import EventEmitter from 'eventemitter3';
import store from './store';
import Router from './router';
import browser from './utils/browser';
import { sensorsInit } from './service/sensors';
import { SentryInit } from './utils/sentry';
import { getSourceInfo } from './service/sourceinfo';
import { wxConfigInit } from './service/wxConfig';
import { logout } from './service/login';
import { setCookie, getCookie, delCookie } from './utils/cookie';
import { GZH_SOURCE } from './utils/keys';
import * as serviceWorker from './serviceWorker';
import './assets/css/index.less';
import { getEnvStr, getEnv } from './utils';
import { isIframe, jsbridgeReady } from './utils/jsbridge';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// 创建一个 client
const queryClient = new QueryClient();

// 获取URL参数
const search = qs.parse(window.location.search);

import VConsole from 'vconsole';

// 存储brower信息在window中
window['browser'] = browser();
// 创建观察者
window['observer'] = new EventEmitter();

// 神策初始化
sensorsInit(!!search?.sensors);
// sentry 错误上报初始化
SentryInit(!!search?.debug);

delCookie(getEnvStr('WXUserData'));

(async () => {
    let sourceInfo = await getSourceInfo();
    // 获取之前存储的公众号source
    let source = getCookie(GZH_SOURCE);
    // 微信公众号环境 && 有公众号source && 与当前获取的公众号source 不同
    if (window['browser'].isgzh && !!source && source !== sourceInfo.source) {
        // 获取当前标签是否从iframe开始 - 用于修复直播间iframe内嵌小风车链路重新登录问题
        let startWithIframe = sessionStorage.getItem('startWithIframe') === '1' || isIframe();

        // 若是有iframe开始访问页面，记录当前状态，不执行退出操作
        if (startWithIframe) {
            sessionStorage.setItem('startWithIframe', '1');
        } else {
            // 清除当前登录状态
            logout(true);
        }
    }

    window['sourceInfo'] = sourceInfo;
    setCookie(GZH_SOURCE, sourceInfo.source);

    // 微信签名初始化
    await wxConfigInit(sourceInfo);

    // app中等待native.js 加载完成
    jsbridgeReady(() => {
        ReactDOM.render(
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <Router />
                </QueryClientProvider>
            </Provider>,
            document.getElementById('root')
        );
    });
})();

if (search?.debug) {
    new VConsole();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
