import qs from 'query-string';
import LStorage from '../utils/LStorage';
import { getSourceInfo } from './sourceinfo';
import { WX_OPENID, AUTHOR_TIMESTAMP } from '../utils/keys';

/**
 * 检查当前页面是否微信授权，如果没有就去授权
 * @param authorType 授权模式 "forcibly" 强制授权 "silence" 静默授权
 * @param local 只查本地，没有就报错
 * @returns openid
 */
export default (authorType: 'forcibly' | 'silence', local = false): Promise<string> =>
    new Promise(async (resolve, reject) => {
        // 获取公众号信息
        let info = await getSourceInfo();

        /* 第一步 --> 检查是否已经授权 */
        // 是否已经授权获取openid
        let wxopenid = LStorage.get(`${info.weChatAppId}_${authorType}_${WX_OPENID}`);
        if (!!wxopenid) {
            return resolve(wxopenid);
        } else {
            // 若是查询静默授权可用强制授权替换
            if (authorType == 'silence') {
                // 尝试查询强制授权openid
                let forciblyWxopenid = LStorage.get(`${info.weChatAppId}_${'forcibly'}_${WX_OPENID}`);
                if (!!forciblyWxopenid) {
                    return resolve(forciblyWxopenid);
                }
            }
        }

        /* 第二步 --> 检查是否为授权返回链接 */
        // 获取url中的参数
        let { url, query } = qs.parseUrl(window.location.href);

        // 获取参数中授权相关参数
        let { openid, status, author_ts, ...search } = query;

        // 获取本地授权标识
        let local_author_ts = LStorage.get(AUTHOR_TIMESTAMP);

        // 参数标识 == 本地标识 && openid && status，表示已经通过授权跳转
        if (author_ts == local_author_ts && openid && status) {
            LStorage.set(`${info.weChatAppId}_${authorType}_${WX_OPENID}`, openid);
            return resolve(openid as string);
        }

        if (!!local) {
            return reject('无openid');
        }

        /* 第三步 --> 跳转授权链接 */
        // 获取当前时间戳作为本地授权标识
        let timestamp = Date.now().toString();
        // 保存标识
        LStorage.set(AUTHOR_TIMESTAMP, timestamp);

        // 授权成功返回链接
        let redirectUri = qs.stringifyUrl({
            url,
            query: {
                ...search,
                // 添加授权唯一标识
                author_ts: timestamp,
            },
        });

        // 获取授权跳转链接
        let authorUrl = qs.stringifyUrl({
            url: `https://wechat.gaodunwangxiao.com/connect/oauth2/authorize`,
            query: {
                appid: info.weChatAppId,
                redirectUri,
                scope: authorType == 'forcibly' ? 'snsapi_userinfo' : 'snsapi_base',
            },
        });

        // 跳转授权页面
        window.location.href = authorUrl;
    });
