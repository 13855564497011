import React from 'react';
import qs from 'query-string';
import connect from '../store/connect';
import authentication, { autherAndLogin } from '../service/authentication';
import DrawerDialog from '../components/common/drawer-dialog';
import PageLoading from '../components/page-loading';
import Login from '../components/login';
import { getSourceInfo } from '../service/sourceinfo';
import { wxShareCfg } from '../service/wxConfig';
import { ACCESS_TOKEN, GD_ZDY, PROJECT_INFO, USER_INFO } from '../utils/keys';
import { checkLogin } from '../service/api';
import LStorage from '../utils/LStorage';
// import { setCustomArgs } from '../service/CustomArgsService';
import { isApp } from '@gaodun.com/rainbow';
import { isIframe } from '../utils/jsbridge';
import { setCookie } from '../utils/cookie';
import { getEnvStr } from '../utils';
import { urlDelByName } from '../utils/urlDelByName';
import { sensorsLogin } from '../service/sensors';
import { getUserLoginInfo } from '../service/userInfo';

export default (route: routeItem) => {
    class RouteHook extends React.Component<Props, State> {
        constructor(props) {
            super(props);
            this.state = {
                authorized: false,
                waitLogin: false,
            };
        }
        emptyOpt = {
            des: '您还未登录',
            btnText: '登  录',
            btnClick: () => this.onOpenChange(true),
        };
        setDocumentTitle(title: string) {
            document.title = title;
        }

        async componentWillMount() {
            // 获取url中的search信息
            const { location } = this.props.history;
            let params = qs.parse(location.search);
            // 若包含GD_ZDY 参数
            // setCustomArgs(params[GD_ZDY] as string);

            // 检查并添加关键字
            this.redirectByAddKeyword();

            // 如果配置了Name，将name作为title写入document.title
            if (route.name) {
                this.setDocumentTitle(route.name);
            }

            // 获取url token 参数
            if (params.accesstoken) {
                setCookie(ACCESS_TOKEN, params.accesstoken)
                // 更改 url 去掉token参数
                window.location.href = urlDelByName('accesstoken')
            }

            // 检查用户登录状态
            await this.checkUserLoginStatus(params);

            // 重新设置微信分享信息
            await this.resetWeixinShareCfg();

            // 初始化用户信息
            this.props.updataUserLoginInfo();

            this.setState({
                authorized: true,
            });
        }

        // 检查是否含有指定关键字，若没有获取关键字拼接url重定向
        redirectByAddKeyword = () => {
            const { location } = this.props.history;
            let params = qs.parse(location.search);

            // 如果url中没有标志信息
            if (!('projectId' in params && 'collegeId' in params && 'x_field_1' in params && 'shopId' in params)) {
                // 获取项目和分校信息
                const projectInfo = sessionStorage.getItem(PROJECT_INFO);
                if (!!projectInfo) {
                    let project = JSON.parse(projectInfo);
                    // 将项目和分校信息加在url中重定向
                    this.props.history.replace(
                        qs.stringifyUrl({
                            url: location.pathname,
                            query: {
                                ...params,
                                ...project,
                            },
                        })
                    );
                }
            }
        };

        checkUserLoginStatus = async params => {
            // 检查用户登录状态 wxsilence==1指定wx静默授权
            const loginStatusNum = await authentication(params['authored'] || route.authorized);
            
            // search参数有meetingID即glive进入
            if (params.meetingID) {
                // 保存入口meeting信息
                localStorage.setItem('glive_meeting_id', params.meetingID as string);
            }

            // 已登录 && 公众号环境 && 需授权 &&  --> 检查授权，若无则唤起授权（确保信息完整）
            if (loginStatusNum === 1 && window['browser'].isgzh && !!route.authorized) {
                try {
                    await autherAndLogin(route.authorized);
                } catch (error) {
                    console.log(error);
                }
            }

            // 已登录
            if (loginStatusNum === 1) {
                let {userInfo} = await getUserLoginInfo();
                // 无用户信息 -->  补全用户信息
                if(!userInfo?.StudentId) {
                    let res = await checkLogin();
                    if (!res.status && res.result) {
                        userInfo = res.result.UserData;
                        LStorage.set(USER_INFO, res.result.UserData);
                    }
                }
                // 神策数据更新，主要为非当前系统登录补数据
                sensorsLogin(userInfo?.StudentId);
            }

            // 非登录状态 && (非游客 || (app中 && 非iframe环境)))
            if (loginStatusNum !== 1 && (!route.visitor || (isApp() && !isIframe()))) {
                this.onOpenChange(true);
            }
        };

        resetWeixinShareCfg = async () => {
            // 微信环境设置公众号相关内容
            if (window['browser'].isgzh) {
                let sourceInfo = await getSourceInfo();
                // 更新微信分享内容
                wxShareCfg(document.title, window.location.href, sourceInfo.weChatAvatar, window.location.href);
            }
        };

        onOpenChange = (open: boolean) => {
            this.props.updataBindPhoneVisit(open);
            this.setState({
                waitLogin: open,
            });
        };

        handleLoginSuccess = () => {
            this.props.updataUserLoginInfo();
            this.onOpenChange(false);
            window['observer']?.emit('loginSuccess');
        };

        handleLoginClose = (open: boolean) => {
            this.onOpenChange(open);
        };

        render() {
            let { authorized, waitLogin } = this.state;
            let { loginStatus, bindPhoneVisit } = this.props;

            return (
                // 验证时loading，验证结束后_严格验证路由未登录显示登录提示
                <>
                    <PageLoading
                        loading={!authorized}
                        empty={route.userStrict && (!loginStatus.accessToken || loginStatus.needPhoneBind)}
                        emptyOpt={this.emptyOpt}
                    >
                        {!waitLogin && <route.component route={route} />}
                    </PageLoading>

                    {(!loginStatus.accessToken || loginStatus.needPhoneBind) && (
                        <DrawerDialog showClose="left" visible={bindPhoneVisit} onOpenChange={this.handleLoginClose}>
                            <Login loginStatus={loginStatus} onSuccess={this.handleLoginSuccess} />
                        </DrawerDialog>
                    )}
                </>
            );
        }
    }

    return connect(RouteHook);
};
