/**
 * localStorage方法封装
 */
const LStorage = {
    set: (key: string, value: any) => {
        if (!value) {
            value = '';
        }
        if (typeof value === 'object' || typeof value === 'function') {
            value = JSON.stringify(value);
        }
        return localStorage.setItem(key, value);
    },
    get: (key: string): any => {
        let value = localStorage.getItem(key);
        if (!value) {
            return null;
        }
        try {
            let obj = JSON.parse(value);
            return obj;
        } catch (error) {
            return value;
        }
    },
    remove: (key: string) => {
        localStorage.removeItem(key);
    },
    clear: () => {
        localStorage.clear();
    },
};

export default LStorage;
