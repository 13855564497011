import Cookies from 'js-cookie';
import { getDomain } from '.';

type CookieOption = {
    path?: string;
    domain?: string;
    expires?: number;
    sameSite?: 'strict' | 'Lax' | 'None';
    secure?: boolean;
};

const hostDomain = getDomain();

let defaultCookieOption: CookieOption = { path: '/', domain: hostDomain, expires: 7 };
// 若当前对象不是top对象 ==> 在iframe中
if (window.self != window.top) {
    console.log('accross host');
    // 设置 sameSite secure 允许跨域写入cookie
    defaultCookieOption.sameSite = 'None';
    defaultCookieOption.secure = true;
}

export function setCookie(name: string, value: string | object, option: CookieOption = defaultCookieOption) {
    if (!value) {
        value = '';
    }
    if (typeof value === 'object' || typeof value === 'function') {
        value = JSON.stringify(value);
    }
    Cookies.set(name, value, option);
}

export function getCookie(key: string) {
    let value = Cookies.get(key);
    if (!value) {
        return null;
    }
    try {
        let obj = JSON.parse(value);
        return obj;
    } catch (error) {
        return value;
    }
}

export function delCookie(key: string, option: CookieOption = { path: '/', domain: hostDomain }) {
    return Cookies.remove(key, option);
}
