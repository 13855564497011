import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import routes from './routes';
import RouteHook from './route-hook';
// import { createBrowserHistory } from "history";
// const history = createBrowserHistory()
export default () => (
    <BrowserRouter>
        <Switch>
            {routes.map((route: routeItem, index: number) => (
                <Route path={route.path} component={RouteHook(route)} exact={route.exact} key={index} />
            ))}
        </Switch>
    </BrowserRouter>
);
