import { UPDATA_LOGIN_STATUS, UPDATA_BINDPHONE_VISIT } from '../types/account';
import { getUserLoginInfo } from '../../service/userInfo';
import { jsbridgeRun } from '../../utils/jsbridge';
import { pageResume, pushWindow } from '@gaodun.com/rainbow';

export const updataUserLoginInfo = () => {
    return {
        type: UPDATA_LOGIN_STATUS,
        payload: getUserLoginInfo(),
    };
};
export const updataBindPhoneVisit = (visit: boolean) => {
    return jsbridgeRun({
        new: () => {
            // 打开登陆
            if (visit) {
                // 跳转app登录页
                pushWindow({
                    url: 'gaodunapp://gd/login',
                });
                // 监听返回方法==>重新加载页面 -- 副作用：返回又会打开登录页
                pageResume(event => {
                    window.location.reload();
                });
                return {
                    type: UPDATA_BINDPHONE_VISIT,
                    data: !visit,
                };
            } else {
                return {
                    type: UPDATA_BINDPHONE_VISIT,
                    data: visit,
                };
            }
        },
        web: () => {
            return {
                type: UPDATA_BINDPHONE_VISIT,
                data: visit,
            };
        },
    });
};
