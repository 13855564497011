import qs from 'query-string';
import { querySourceInfo, querySourceInfoByFlag, querySourceInfoByShopId } from './api';
import { SOURCE_INFO, PROJECT_INFO } from '../utils/keys';

type SourceInfoType = {
    collegeId: number;
    collegeName: string;
    project: string;
    projectId: number;
    shopId: number;
    shopName: string;
    source: string;
    weChatAccount: string;
    weChatAppId: string;
    weChatAvatar: string;
    weChatName: string;
    weChatQrCode: string;
};

/**
 * 获取公众号信息
 */
export const getSourceInfo = (): Promise<SourceInfoType> => {
    // 获取url中的search信息
    let { projectId, collegeId, x_field_1, shopId, h5id }: any = qs.parse(window.location.search);
    // 存储获取source的关键信息
    sessionStorage.setItem(
        PROJECT_INFO,
        JSON.stringify({
            projectId,
            collegeId,
            x_field_1,
            shopId,
            h5id,
        })
    );
    // 是否存在店铺
    if (shopId && shopId !== 'undefined') {
        return getSourceInfoByShopId(Number(shopId));
    }
    // 是否存在标识
    if (x_field_1) {
        return getSourceInfoByXfield(x_field_1);
    }

    return getSourceInfoByProjectIdAndcollegeId(projectId, collegeId);
};

/**
 * 通过xfield获取source
 * @param x_field_1 flag
 */
export const getSourceInfoByXfield = (x_field_1): Promise<SourceInfoType> =>
    new Promise((resolve, reject) => {
        let sourceInfo: any = sessionStorage.getItem(`${SOURCE_INFO}_xfield_${x_field_1}`);
        // 如果已经存在
        if (!!sourceInfo) {
            resolve(JSON.parse(sourceInfo));
        } else {
            querySourceInfoByFlag({
                flag: x_field_1,
            }).then((data: any) => {
                if (!data.status) {
                    sessionStorage.setItem(`${SOURCE_INFO}_xfield_${x_field_1}`, JSON.stringify(data.result));
                    resolve(data.result);
                }
                resolve({} as any);
            });
        }
    });

/**
 * 通过shopId 获取source
 * @param shopId 店铺id
 */
export const getSourceInfoByShopId = (shopId): Promise<SourceInfoType> =>
    new Promise((resolve, reject) => {
        let sourceInfo: any = sessionStorage.getItem(`${SOURCE_INFO}_shopId_${shopId}`);
        // 如果已经存在
        if (!!sourceInfo) {
            resolve(JSON.parse(sourceInfo));
        } else {
            querySourceInfoByShopId({
                shopId: shopId,
            }).then((data: any) => {
                if (!data.status) {
                    sessionStorage.setItem(`${SOURCE_INFO}_shopId_${shopId}`, JSON.stringify(data.result));
                    resolve(data.result);
                }
            });
        }
    });

/**
 * 通过projectId, collegeId 获取source
 * @param projectId 项目id
 * @param collegeId 分校id
 */
export const getSourceInfoByProjectIdAndcollegeId = (projectId, collegeId): Promise<SourceInfoType> =>
    new Promise((resolve, reject) => {
        let sourceInfo: any = sessionStorage.getItem(`${SOURCE_INFO}_${projectId}_${collegeId}`);
        // 如果已经存在
        if (!!sourceInfo) {
            resolve(JSON.parse(sourceInfo));
        } else {
            querySourceInfo({
                projectId,
                collegeId,
            }).then((data: any) => {
                if (!data.status) {
                    sessionStorage.setItem(`${SOURCE_INFO}_${projectId}_${collegeId}`, JSON.stringify(data.result));
                    resolve(data.result);
                }
            });
        }
    });
