import { qs } from "@gaodun.com/gtools";

/**
 * location search 删除一个字段
 */
export function urlDelByName(name: string) {
    const loca = window.location;
    const query = decodeURIComponent(loca.search.replace('?',''));
    if (!query) {
        return loca.href;
    }
    if (loca.href.indexOf(name) > -1) {
        const obj = {};
        const arr = query.indexOf("&") > -1 ? query.split("&") : [query];
        for (let i = 0; i < arr.length; i++) {
            //@ts-ignore
            arr[i] = arr[i].split("=");
            obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        const url = `${loca.origin}${loca.pathname}?${qs.stringify(obj)}`
        return url;
    }

    return loca.href
}
